.setting-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    margin: 15px;
}

.setting-title {
    color: black;
    align-self: center;
    font-size: xx-large;
    text-align: center;
}

.interactables {
    align-self: center;
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.App {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100dvh;
}

.Body-desktop {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  width: 100%;
  height: 100%;
}

.Body-mobile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap-reverse;
  margin-top: 65px;
  margin-bottom: 45px;
  width: 100%;
  height: 100%;
}

.settings-desktop {
  width: 380px;
  height: auto;
  padding: 10px;
  margin-top: auto;
  margin-bottom: auto;
  margin: 10px;
  border-radius: 10px;
  background-color: #1976d2;
  display: flex;
  flex-direction: column;
}

.settings-mobile {
  width: 380px;
  height: auto;
  padding: 5px;
  border-radius: 10px;
  background-color: #1976d2;
  display: flex;
  flex-direction: column;
}

.drawer-content {
  display: flex;
  justify-content: center;
  justify-items: center;
}

@media (max-width: 400px) {
  .SVG {
    flex: 1 1 100%;
  }
}

.SVG {
    width: 100%;
    height: 100%;
    flex: 1;
}

.APDSsettings-Title {
    color: black;
    align-self: center;
    font-size: xx-large;
    text-align: center;
}

.APDSsettings-IconDisplay {
    padding: 10px;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 10px; 
    background-color: #1976d2;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100px;
    margin-right: auto;
    margin-left: auto;
}

.APDSsettings-Inverted {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.title {
  color: white;
}

.header {
  width: 100%;
  min-height: 65px;
  overflow: visible;
  justify-content: center;
}

@media (max-width: 450px) {
  .save {
    font-size: 1px;
    margin-right: 5px;
  }
}

.save {
  margin-left: auto;
  margin-right: 10px;
}

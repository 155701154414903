.content {
  margin-left: auto;
  margin-right: auto;
  background: rgb(248, 248, 248);
  height: auto;
  width: 80%;
}

.content .item:last-child {
  margin-bottom: 100px;
}

.item {
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  height: 100vh;
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: flex-start;
  margin: 20px;
  margin-top: 100px;
  border-radius: 10px; 
  width: 80%;
  height: auto;
}

.description h1 {
  text-align: center;
  padding: 10px;
  font-size: 60px;
  color: white;
  width: auto;
}

.description h3 {
  text-align: left;
  padding: 20px;
  font-size: 30px;
  color: white;
}

.description div {
  color: white;
}
.settings-head {
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: center;
}

.settings-head-body {
  display: flex;
  align-self: flex-start;
}

.settings-header-interactables {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.Input {
  padding-left: 75px;
}

.generator-settings {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}

.settings-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 50px;
}

.Title {
  font-size: 30px;
}
